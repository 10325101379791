exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-all-investments-tsx": () => import("./../../../src/pages/all_investments.tsx" /* webpackChunkName: "component---src-pages-all-investments-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-fraudulent-schemes-warning-tsx": () => import("./../../../src/pages/fraudulent_schemes_warning.tsx" /* webpackChunkName: "component---src-pages-fraudulent-schemes-warning-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-japan-disclaimer-tsx": () => import("./../../../src/pages/japan_disclaimer.tsx" /* webpackChunkName: "component---src-pages-japan-disclaimer-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-northstar-about-tsx": () => import("./../../../src/pages/northstar/about.tsx" /* webpackChunkName: "component---src-pages-northstar-about-tsx" */),
  "component---src-pages-northstar-error-tsx": () => import("./../../../src/pages/northstar/error.tsx" /* webpackChunkName: "component---src-pages-northstar-error-tsx" */),
  "component---src-pages-northstar-home-tsx": () => import("./../../../src/pages/northstar/home.tsx" /* webpackChunkName: "component---src-pages-northstar-home-tsx" */),
  "component---src-pages-northstar-insights-insights-dashboard-tsx": () => import("./../../../src/pages/northstar/insights/insights_dashboard.tsx" /* webpackChunkName: "component---src-pages-northstar-insights-insights-dashboard-tsx" */),
  "component---src-pages-northstar-insights-publication-tsx": () => import("./../../../src/pages/northstar/insights/publication.tsx" /* webpackChunkName: "component---src-pages-northstar-insights-publication-tsx" */),
  "component---src-pages-northstar-login-tsx": () => import("./../../../src/pages/northstar/login.tsx" /* webpackChunkName: "component---src-pages-northstar-login-tsx" */),
  "component---src-pages-northstar-resources-resource-tsx": () => import("./../../../src/pages/northstar/resources/resource.tsx" /* webpackChunkName: "component---src-pages-northstar-resources-resource-tsx" */),
  "component---src-pages-northstar-resources-tsx": () => import("./../../../src/pages/northstar/resources.tsx" /* webpackChunkName: "component---src-pages-northstar-resources-tsx" */),
  "component---src-pages-northstar-tools-application-tsx": () => import("./../../../src/pages/northstar/tools/application.tsx" /* webpackChunkName: "component---src-pages-northstar-tools-application-tsx" */),
  "component---src-pages-northstar-tools-reports-tsx": () => import("./../../../src/pages/northstar/tools/reports.tsx" /* webpackChunkName: "component---src-pages-northstar-tools-reports-tsx" */),
  "component---src-pages-northstar-tools-tools-dashboard-tsx": () => import("./../../../src/pages/northstar/tools/tools_dashboard.tsx" /* webpackChunkName: "component---src-pages-northstar-tools-tools-dashboard-tsx" */),
  "component---src-pages-northstar-tools-view-tsx": () => import("./../../../src/pages/northstar/tools/view.tsx" /* webpackChunkName: "component---src-pages-northstar-tools-view-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy_policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-summit-agenda-tsx": () => import("./../../../src/pages/summit/agenda.tsx" /* webpackChunkName: "component---src-pages-summit-agenda-tsx" */),
  "component---src-pages-summit-home-tsx": () => import("./../../../src/pages/summit/home.tsx" /* webpackChunkName: "component---src-pages-summit-home-tsx" */),
  "component---src-pages-summit-register-tsx": () => import("./../../../src/pages/summit/register.tsx" /* webpackChunkName: "component---src-pages-summit-register-tsx" */),
  "component---src-pages-summit-speakers-tsx": () => import("./../../../src/pages/summit/speakers.tsx" /* webpackChunkName: "component---src-pages-summit-speakers-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-user-agreement-tsx": () => import("./../../../src/pages/user_agreement.tsx" /* webpackChunkName: "component---src-pages-user-agreement-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

